<template>
  <nav class="header" style="display:flex; justify-content: space-between; background:#fff !important;">
    <!-- <css-seamless-scroll :datas="notices" direction="left" :hover="true" class="scorll">
      <ul class="item">
        <li v-for="(item, index) in notices" :key="index" v-text="item.title"></li>
      </ul>
    </css-seamless-scroll> -->
    <div></div>
    <div class="hd-user-a">
      <!-- <a href="javascript:void(0)" :class="{
        'm-subnav-a': true,

        'select-red': store.state.app.navigation == 'notice',
      }" @click="navRedirictEvent('notice', '/game/notice')">通知公告</a> -->

      <a href="javascript:void(0)" :class="{
        'm-subnav-a': true,

        'select-red': store.state.app.navigation == 'bet',
      }" @click="navRedirictEvent('bet', '/user/bet')">明細</a>

      <a href="javascript:void(0)" :class="{
        'm-subnav-a': true,

        'select-red': store.state.app.navigation == 'report',
      }" @click="navRedirictEvent('report', '/game/report')">報表</a>

      <a href="javascript:void(0)" :class="{
        'm-subnav-a': true,

        'select-red': store.state.app.navigation == 'backwater',
      }" @click="navRedirictEvent('backwater', '/user/backwater')">退水資料</a>

      <a href="javascript:void(0)" :class="{
        'm-subnav-a': true,

        'select-red': store.state.app.navigation == 'result',
      }" @click="navRedirictEvent('result', '/game/result')">開獎結果</a>

      <a href="javascript:void(0)" :class="{
        'm-subnav-a': true,

        'select-red': store.state.app.navigation == 'rule',
      }" @click="navRedirictEvent('rule', '/game/rule')">規則</a>

      <a href="javascript:void(0)" :class="{
        'm-subnav-a': true,

        'select-red': store.state.app.navigation == 'pass',
      }" @click="navRedirictEvent('pass', '/user/pass')">密碼</a>

      <!-- <a href="javascript:void(0)" :class="{
        'm-subnav-a': true,

        'select-red': store.state.app.navigation == 'logs',
      }" @click="navRedirictEvent('logs', '/user/log')">日誌</a> -->

      <span class="hd-user">
        账号：{{ playerInfo.nickName }}
        <!-- (IP:{{
          playerInfo.lastLoginIP
        }}) -->
      </span>
      <a class="m-subnav-a" href="javascript:void(0)" @click="logoutEvent">登出</a>
    </div>
  </nav>

  <div style="width:100%;">
    <div class="hd">
      <div class="hd-box f-flex">


        <div class="m-l">
          <div class="u-logo f-flex">
            <img class="u-image" src="@/assets/images/logo-small.png" />
          </div>
          <div class="m-lty">
            <div class="m-lty-lbg">
              <div class="m-lty-con f-flex">
                <div class="m-lty-cai">
                  <div class="m-lty-t f-flex">
                    <div class="m-lty-name">{{ currentGame.name }}</div>
                  </div>

                  <div class="m-lty-stg">第{{ aomlhc.termNumber }}期</div>
                </div>

                <div class="m-lty-ball f-flex" v-if="(aomlhc.status == 0 || aomlhc.status == 2) && aomlhc.result">
                  <div class="ball" v-for="(item, index) in aomlhc.result.split(',')" :key="index">
                    <div :class="{
                      'ball-num': true,

                      'f-bg-green': filterColorOrZodiac('color', item) == 'green',

                      'f-bg-red': filterColorOrZodiac('color', item) == 'red',

                      'f-bg-blue': filterColorOrZodiac('color', item) == 'blue',
                    }" v-if="currentGame.code < 20000">{{ item }}</div>

                    <div class="ball-value" v-if="currentGame.code < 20000">{{ aomlhc.resultSx[index] }}</div>

                    <!--3D-->

                    <div :class="{
                      'ball-num': true,

                      'f-bg-blue': true,
                    }" v-if="currentGame.code > 20000">{{ item }}</div>

                    <div class="ball-value" v-if="currentGame.code > 20000">{{ Number(item) > 4 ? "大" : "小" }}</div>
                  </div>
                </div>

                <div class="m-lty-ball f-flex" v-if="aomlhc.status == 0">
                  <div class="m-lty-state" v-if="currentGame.code < 20000">
                    <div class="f-fwb">
                      非特收單:
                      <span class="f-f-blue f-fwb">{{ timerText }}</span>
                    </div>

                    <div class="f-fwb">
                      特碼收單:
                      <span class="f-f-red f-fwb">{{ timerText }}</span>
                    </div>
                  </div>

                  <div class="m-lty-state-ftc" v-else>
                    <div class="f-fwb">
                      收單剩餘:
                      <span class="f-f-blue f-fwb">{{ timerText }}</span>
                    </div>
                  </div>
                </div>

                <div class="m-lty-ball f-flex" v-if="aomlhc.status == 1">
                  <div class="m-lty-state" v-if="currentGame.code < 20000">
                    <div class="f-fwb">
                      非特封盤:
                      <span class="f-f-blue f-fwb">{{ timerText }}</span>
                    </div>

                    <div class="f-fwb">
                      特碼封盤:
                      <span class="f-f-red f-fwb">{{ timerText }}</span>
                    </div>
                  </div>

                  <div class="m-lty-state-ftc" v-else>
                    <div class="f-fwb"  style="line-height:24px;">
                      投注剩餘:<br/>
                      <span class="f-f-blue f-fwb">{{ timerText }}</span>
                    </div>
                  </div>
                </div>

                <div class="m-lty-ball f-flex" v-if="aomlhc.status == 2 && !aomlhc.hasOwnProperty('secronds')">
                  <div class="m-lty-state" v-if="currentGame.code < 20000">
                    <div class="f-fwb">
                      非特封盤:
                      <span class="f-f-blue f-fwb">已封盤</span>
                    </div>

                    <div class="f-fwb">
                      特碼封盤:
                      <span class="f-f-red f-fwb">已封盤</span>
                    </div>
                  </div>

                  <div class="m-lty-state-ftc" v-else>
                    <div class="f-fwb" style="line-height:24px;">
                      投注剩餘:<br/>
                      <span class="f-f-blue f-fwb">{{ timerText }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="m-r f-flex">
          <CommonNavbar :navigation="navigation" @on-switch-game-reload="switchGameReloadEnvent"></CommonNavbar>
        </div>
      </div>
    </div>

    <div class="m-left">
      <div class="left-username">
        <div class="left-username-bg">账號： {{ playerInfo.nickName }}</div>
      </div>

      <div v-if="currentGame.code == '20003'" class="left-li">
        <div>可用余額： {{(playerInfo.totalWithdraw || 0).toFixed(2)  }}</div>
      </div>
      <div v-else class="left-li">
        <div>信用額度： {{ playerInfo.totalDeposit }} </div>

        <div>已用額度： {{ playerInfo.totalDeposit - playerInfo.amount }}</div>

        <div>可用余額： {{ playerInfo.amount }}</div>
      </div>

      <!--盘口选择-->

      <div class="crm f-flex">
        <div class="crm-tab f-flex">
          <div :class="{ 'crm-tab-li': true, 'crm-cur': currentGame.plat == item }" v-for="(item, index) in playerInfo.curPlatCode
            ? playerInfo.curPlatCode.split(',')
            : []" :key="index" @click="item != currentGame.plat && switchPlatEvent(item)">{{ item == "0" ? "A" : item
    ==
    "1" ? "B" : "C" }}</div>
        </div>
      </div>

      <div class="f-flex left-b">
        <div class="g-w35">
          <button class="left-btn1" @click="redirectOfficialEvent"
            v-if="currentGame.code == 10001 || currentGame.code > 20000">開獎視頻</button>
        </div>

        <div class="g-w40">
          <button class="left-btn2">↓最近10筆↓</button>
        </div>

        <div class="g-w25">
          <button class="left-btn3" @click="flushBetEvent">刷新</button>
        </div>
      </div>

      <div class="left-bet" v-if="store.state.game.lastBets.length > 0">
        <div class="left-bet-li" v-for="(item, index) in store.state.game.lastBets" :key="index">
          <div class="f-flex">
            <div class="g-w75 f-pre">{{ connectStr(item) }}</div>
            <div class="g-w25 f-tar f-f-red f-fwb">{{ item ? item.betBaseMoney : "" }}</div>
          </div>
          <div style="color:#999;">{{ item ? formatTime(item.gmtCreate, "HH:mm") : "" }}</div>
        </div>
      </div>

      <div class="left-bet" v-else>
        <div class="left-bet-li f-flex" v-for="(item, index) in 10" :key="index">
          <div class="g-w35"></div>

          <div class="g-w40 f-tac"></div>

          <div class="g-w25 f-tar f-f-red f-fwb"></div>
        </div>
      </div>
    </div>
  </div>





  <div class="goTop" @click="scrollToTopEvent"></div>

  <div class="body-con">
    <div class="noQuotation noselect" v-if="!openGamePlat">
      <div class="text">游戏未开放...</div>
    </div>

    <router-view />
  </div>

  <!--首頁彈窗公告 -->
  <ModalNotice ref="modalNotice" :records="dialog.records" :loading="dialog.loading"
    @page-change="noticePageChangeEvent" />
</template>
<script>
import { onMounted, ref, computed, nextTick, reactive, onUnmounted } from "vue";
import CommonNavbar from "@/components/common-navbar.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { timeCountDown } from "@/hooks";
import Confirm from "@/components/library/Confirm";
import Alert from "@/components/library/Alert";
import moment from "moment";
import find from "lodash.find";
import { cssSeamlessScroll } from "vue3-seamless-scroll";
import ModalNotice from "@/views/notice/components/notice-modal.vue";
import { shuangmianTB } from "@/config/ssc.config";
import Message from "@/components/library/Message";
import { isJsGame } from "@/utils/tools"
export default {
  name: "Layout",
  components: {
    CommonNavbar,
    cssSeamlessScroll,
    ModalNotice,
    Message
  },
  setup(props) {
    let dialog = reactive({
      loading: false,
      records: {
        data: [],
        total: 0
      }
    });
    // window.addEventListener("beforeunload", (e) => {
    //   beforeunloadEvent(e);
    // });

    document.addEventListener("visibilitychange", e => {
      switch (e.target.visibilityState) {
        case "visible":
          requestGameInfo();
          break;
      }
    });

    const store = useStore();
    const router = useRouter();
    const { timerStart, timerText } = timeCountDown();
    let timerId = ref(null);
    //玩家基本信息
    const playerInfo = computed(() => {
      return store.state.player.profile;
    });
    let navigation = computed(() => {
      return store.state.app.navigation;
    });
    let currentGame = computed(() => {
      return store.state.app.currentGame;
    });

    //基础投註球號
    const baseNumbers = computed(() => {
      return store.state.game.baseNumbers;
    });
    /**
     * 是否开放盘口
     */
    let openGamePlat = computed(() => {
      if (!playerInfo.value.hasOwnProperty("openGameCode")) {
        return true;
      } else {
        // console.log('playerInfo.value.openGameCode',playerInfo.value.openGameCode)
        return playerInfo.value.openGameCode.includes(currentGame.value.code);
      }
    });
    let aomlhc = reactive({
      status: 0,
      termNumber: "",
      result: "",
      secronds: 0,
      resultSx: []
    });
    const notices = ref([]);

    let timer;

    const tipNewAom = () => {
      if (currentGame.value.code == '10004') {
        Alert({ text: '請注意：當前頁面下注彩種為 新澳六；此彩種2023115期（23年4月25日）開獎結果 特碼為：15。' });
      }
    }
    onMounted(() => {
      choseBgColorEvent(currentGame.value.code);

      nextTick(() => {
        store
          .dispatch("player/requestAccountInfoAction", {
            gameCode: currentGame.value.code
          })
          .then(res => {
            if (res && res.code == 0) {
              const {
                accountId,
                nickName,
                portrait,
                mobile,
                lastLoginIP,
                amount,
                totalDeposit,
                loginName,
                curPlatCode,
                openGameCode,
                totalWithdraw
              } = res.res.info;
              store.dispatch("player/playInfoAction", {
                accountId,
                nickName,
                portrait,
                mobile,
                lastLoginIP,
                amount,
                totalDeposit,
                loginName,
                curPlatCode,
                openGameCode,
                totalWithdraw
              });
              store.dispatch("game/setBaseNumbersAction", res.res.numbers);
              store.dispatch("game/setGameConfigAction", res.res.aomlhc);
              Object.assign(aomlhc, res.res.aomlhc);
              if (res.res.aomlhc.resultSx) {
                aomlhc.resultSx = res.res.aomlhc.resultSx.split(",");
              }
              // console.log("game/requestGameInfoAction aomlhc, res.res.aomlhc", aomlhc, res.res.aomlhc);
              if (aomlhc) {
                if (aomlhc.secronds > 0) {
                  timerStart(aomlhc.secronds, () => requestGameInfo());
                }
                console.log('isjsgame---',isJsGame(currentGame.value.code),currentGame.value.code,aomlhc.status)
                if (isJsGame(currentGame.value.code) && aomlhc.status>=0) {
                  timer = setInterval(settleGame, 3000);
                }
              }
            }
          })
          .then(() => {
            requestScrollNotice();
          })
          .then(() => {
            flushBetEvent();
          })
          .then(() => {
            //獲取彈窗公告
            noticePageChangeEvent();
          });
      });

      tipNewAom();


    });
    onUnmounted(() => {

      if (timer) {
        clearInterval(timer);
      }

      // window.removeEventListener("beforeunload", (e) => {
      //      beforeunloadEvent(e);
      // });
    });
    /**
     * 通知公告
     */
    const requestScrollNotice = () => {
      store.dispatch("game/requestScrollNoticeAction").then(res => {
        if (res.code == 0) {
          notices.value = res.res;
        } else {
          notices.value = [];
        }
      });
    };

    // 退出登录
    const logoutEvent = () => {
      Confirm({ text: "確定退出系統?" })
        .then(() => {
          beforeunloadEvent();
        })
        .catch(e => {
          // console.log("取消");
        });
    };

    const beforeunloadEvent = e => {
      store
        .dispatch("player/requestAccountOutAction", {})
        .then(res => {
          if (res.code == 0) {
            clearLocalStorageEvent();
          }
        })
        .catch(() => {
          clearLocalStorageEvent();
        });
    };

    const unloadEvent = e => { };

    const clearLocalStorageEvent = () => {
      store.dispatch("player/playInfoAction", {});
      store.dispatch("player/authTokenAction", "");
      store.dispatch("game/lastBetsAction", {
        bets: [],
        request: true
      });
      const fullPath = encodeURIComponent(router.currentRoute.value.fullPath);
      router.push("/login?redirectUrl=" + fullPath);
    };

    // 刷新註單
    const flushBetEvent = () => {
      store.dispatch("game/requestLastBetsAction", {
        pageNo: 1,
        pageSize: 10,
        dto: {
          termNumber: "1",
          betType: 9999,
          status: 0,
          gameCode: currentGame.value.code
        }
      });
    };

    const requestGameInfo = () => {
      store
        .dispatch("game/requestGameInfoAction", {
          gameCode: currentGame.value.code
        })
        .then(res => {
          // let a = { status: 1, secronds: 30, termNumber: "2021267" };
          if (res && res.code == 0) {
            store.dispatch("game/setGameConfigAction", res.res);
            Object.assign(aomlhc, res.res);
            if (res.res.resultSx) {
              aomlhc.resultSx = res.res.resultSx.split(",");
            }

            return res.res;
          } else {
            return null;
          }
        })
        .then(res0 => {
          if (res0) {
            timerStart(res0.secronds, () => requestGameInfo());
          }
        });
    };

    const settleGame = () => {

      const lastNum = localStorage.getItem("lastSettleNum")

      console.log('lastNum--'+lastNum);
      if (!lastNum || (lastNum && parseInt(lastNum) != parseInt(aomlhc.termNumber) - 1)) {
        store.dispatch("player/getLastSettleNumAction", { gameCode: currentGame.value.code }).then(res => {
          if (res && res.code == 0 && res.res && res.res.termNumber) {
            const resNum=res.res.termNumber
          
            if(lastNum!=resNum){
              store.dispatch("player/getUserAmountAction", {  }).then(res => {
                if (res && res.code == 0 && res.res) {
                  playerInfo.value.totalWithdraw=res.res;
                  localStorage.setItem("lastSettleNum", resNum)
                }
                
              })
            }
          }
        })
          .catch(() => {

          });

      }
    }
    const filterColorOrZodiac = (type, number) => {
      if (currentGame.value.code < 20000) {
        let item = find(baseNumbers.value, itm => {
          return itm.ball == number;
        });
        if (!item) return "";
        switch (type) {
          case "color":
            return item.color;
          // case "zodiac":
          //   console.log('item.zodiac',item.zodiac)
          //   if (aomlhc.resultSx.length != 7) {
          //     return item.zodiac;
          //   }
          //   else { return aomlhc.resultSx; }
        }
      } else {
        return number;
      }
    };
    const formatTime = (time, format) => {
      return moment(time).format(format);
    };
    // 导航切换
    const navRedirictEvent = (name, path) => {
      router.push(path);
      store.dispatch("app/setNavgationNameAction", name);
    };
    const redirectOfficialEvent = () => {
      switch (currentGame.value.code) {
        case 10001:
          window.open("https://www.macau-jc.com", "_blank");
          break;
        case 20001:
          window.open(
            "https://www.cwl.gov.cn/ygkj/kjzb/",
            "_blank"
          );
          break;
        case 20002:
          window.open(
            "https://www.lottery.gov.cn/xytc/index.html",
            "_blank"
          );
          break;
         case 20003:
          window.open(
            "https://www.ttkj123.net",
            "_blank"
          );
          break;
          case 20010:
          window.open(
            "https://www.ttkj123.net",
            "_blank"
          );
          break;
      }
    };
    /**
     * 切换游戏
     */
    const switchGameReloadEnvent = gameCode => {
      choseBgColorEvent(gameCode);
      flushBetEvent();
      setTimeout(() => {
        requestGameInfo();
      }, 300);
      tipNewAom();
    };
    /**
     * 修改背景色
     */
    const choseBgColorEvent = gameCode => {
      switch (gameCode) {
        case 10001:
          window.less.modifyVars({
            "@primary-color": "#5d7878"
          });
          break;
        case 10004:
          window.less.modifyVars({
            "@primary-color": "#5b5491"
          });
          break;
        case 10002:
          window.less.modifyVars({
            "@primary-color": "#5f4444"
          });
          break;
        case 10003:
          window.less.modifyVars({
            "@primary-color": "#30567c"
          });
          break;
        case 20001:
          window.less.modifyVars({
            "@primary-color": "#1e4791"
          });
          break;
        case 20002:
          window.less.modifyVars({
            "@primary-color": "#51459e"
          });
          break;
        case 20003:
          window.less.modifyVars({
            "@primary-color": "#91541e"
          });
          break;
      }
    };

    const scrollToTopEvent = () => {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    };
    /**
     *切换盘口
     */
    const switchPlatEvent = plat => {
      const beforeGame = { ...currentGame.value };
      store.dispatch("app/switchCurrentGameAction", {
        code: beforeGame.code,
        name: beforeGame.name,
        type: beforeGame.type,
        plays: beforeGame.plays,
        plat: plat
      });
    };
    //公告弹窗
    const modalNotice = ref(null);
    /**
     *彈窗公告分頁事件
     */


    const noticePageChangeEvent = (page = 1) => {
      if(sessionStorage.getItem('havePopup_xzy')){
        return false;
      }
      dialog.loading = true;
      store
        .dispatch("game/requestPageNoticeAction", {
          pageNo: page,
          pageSize: 10,
          dto: {
            categoryId: "2"
          }
        })
        .then(res => {
          dialog.loading = false;
          if (res && res.code == 0) {
            if (res.res.total > 0) {

              for (var i = res.res.rows.length - 1; i >= 0; i--) {
                Alert({ text: res.res.rows[i].title });
              }
              sessionStorage.setItem('havePopup_xzy', '1');
            }
          } else {
            dialog.records.data = [];
            dialog.records.total = 0;
          }

        })
        .catch(() => {
          dialog.loading = false;
        });
    };
    const connectStr = item => {
      if (item) {
        if (!item.betContent) {
          return "";
        }

        if (item.hasOwnProperty("betTypeName")) {
          var typename = item.betTypeName;
          var types = typename.split("-");
          if (types && types.length == 2) {
            typename = types[1];
          }
          return item.betContent + "[" + typename + "]";
        } else {
          return item.betContent;
        }
      } else return "";
    };
    return {
      store,
      aomlhc,
      navigation,
      currentGame,
      openGamePlat,
      logoutEvent,
      flushBetEvent,
      playerInfo,
      requestGameInfo,
      filterColorOrZodiac,
      formatTime,
      timerText,
      notices,
      requestScrollNotice,
      navRedirictEvent,
      redirectOfficialEvent,
      switchGameReloadEnvent,
      choseBgColorEvent,
      scrollToTopEvent,
      switchPlatEvent,
      clearLocalStorageEvent,
      noticePageChangeEvent,
      modalNotice,
      dialog,
      beforeunloadEvent,
      unloadEvent,
      connectStr,
      shuangmianTB
    };
  }
};
</script>
<style lang="less" scoped>
.scorll {
  overflow: hidden;
  width: calc(100% - 660px);
  line-height: 40px;

  ul.item {
    width: 100vw;

    li {
      // overflow: hidden;
      float: left;
      margin-right: 10px;
      color: red;
      cursor: pointer;
    }
  }
}

.body-con {
  position: relative;
}

.noQuotation {
  position: fixed;
  top: 126px;
  left: 210px;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
  z-index: 5;
}

.noQuotation {
  .text {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    margin-left: 105px;
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    background-image: -webkit-linear-gradient(bottom, red, #fd8403, yellow);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently

not supported by any browser */
}
</style>
