<template>
  <div class="m-navbox">
      <div class="crm f-flex">
        <div class="crm-tab f-flex">
        <div    v-for="(item, index) in data.games"
            :key="index">
           <div @click="
              currentGame.code != item.value &&
                gameSwitchEvent(item.value, item.key, item.type) "  v-if="isOpenGame(item.value)"
            :class="{
              'crm-tab-li': true,
              'crm-cur': currentGame.code == item.value,
            }"
          ><span >{{ item.key }}
           </span>
          </div>
         </div>
      </div>
    </div>
    <div></div>
    <div class="m-nav">
      <a
        href="javascript:void(0)"
        :class="{
          'm-nav-a': true,
          cur: navigation == item.name,
        }"
        v-for="(item, index) in currentGame.plays.slice(0, 12)"
        :key="index"
        @click="navRedirictEvent(item)"
        >{{ item.title }}
      </a>
      <span
        class="m-nav-a"
        v-if="currentGame.plays.length > 12"
        @click="data.showMore = !data.showMore"
        >{{ data.moreText + "▼" }}
      </span>
      <div
        class="showMore"
        v-if="currentGame.plays.length > 12 && data.showMore"
      >
        <a
          href="javascript:void(0)"
          v-for="(item, index) in currentGame.plays.slice(12, 99)"
          :key="index"
          @click="
            navRedirictEvent(item);
            data.showMore = false;
            data.moreText = item.title;
          "
          >{{ item.title }}</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { computed, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { gameInfo } from "@/config/lhc.config";
import find from "lodash.find";
export default {
  name: "CommonNavbar",
  props: {
    navigation: {
      type: String,
      default: "tema",
    },
  },
  setup(props, { emit }) {
    /**
     * 遊戲類型/玩法
     */
    const data = reactive({
      games: [
        // { key: "澳門六合彩", value: 10001, type: "liuhecai" },
        //  { key: "新澳六", value: 10004, type: "liuhecai" },
        // { key: "香港六合彩", value: 10002, type: "liuhecai" },
        // { key: "台灣六合彩", value: 10003, type: "liuhecai" },
         //{ key: "亚赔极速3D", value: 20003, type: "futicai" },
        { key: "福彩3D", value: 20001, type: "futicai" },
        { key: "体彩P3", value: 20002, type: "futicai" },
        { key: "天天3D", value: 20003, type: "futicai" },
      ],
      showMore: false,
      moreText: "更多",
    });
    const router = useRouter();
    const store = useStore();

      //玩家基本信息
    const playerInfo = computed(() => {
      return store.state.player.profile;
    });
    /**
     * 当前游戏
     */
    const currentGame = computed(() => {
      return store.state.app.currentGame;
    });

    // 导航切换
    const navRedirictEvent = (item) => {
      if (item.id <= 12) {
        data.moreText = "更多";
      }
      data.showMore = false;
      router.push(item.path);
      store.dispatch("app/setNavgationNameAction", item.name);
    };
    // 游戏切换
    const gameSwitchEvent = (gameCode, gameName, gameType) => {
      data.showMore = false;
      let game = find(gameInfo, (element) => {
        return element.type == gameType;
      });
      const beforeGame = { ...currentGame.value };
      store.dispatch("app/switchCurrentGameAction", {
        code: gameCode,
        name: gameName,
        type: gameType,
        plays: game.plays,
        plat: beforeGame.plat,
      });
      emit("on-switch-game-reload", gameCode);
      if (beforeGame.type != gameType) {
        navRedirictEvent(game.plays[0]);
      }

    };

     const isOpenGame = (gameCode) => {
      if (!playerInfo.value.hasOwnProperty("openGameCode")) {
        return false;
      } else {
        // console.log('playerInfo.value.openGameCode',playerInfo.value.openGameCode)
        return playerInfo.value.openGameCode.includes(gameCode);
      }
    };

    
    return {
      currentGame,
      data,
      navRedirictEvent,
      gameSwitchEvent,
      isOpenGame
    };
  },
};
</script>
<style lang="less" scoped>
.showMore {
  position: absolute;
  text-align: center;
  width: 120px;
  right: 0;
  background: #fff;
  color: #333;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.5);
  a {
    color: #333 !important;
    display: block;
    text-align: center;
    padding: 10px;
  }
  a:hover {
    background: #f7f7f7 !important;
  }
}
</style>
